import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// RxJS
import { Subject, BehaviorSubject, firstValueFrom } from 'rxjs';

// NGRX
import { Store, select } from '@ngrx/store';

import { Product } from 'src/app/shared/classes/product.model';
import { CartItem } from 'src/app/shared/classes/cart-item.model';
import { cartHeaderSelector, cartItemsSelector } from 'src/app/core/selectors/selectors';
import { UpdateCartHeaderAction, AddToCartAction, RemoveFromCartAction, UpdateCartAction } from 'src/app/core/actions/actions';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { WSResponseModel } from '../classes/WSResponseModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CartHeader } from '../classes/cart-header.model';
import { PrezziSpedizioneService } from './prezzi-spedizione.service';
import { ApiService } from './api.service';


@Injectable({
	providedIn: 'root'
})

export class CartService {

	public cartHeader: CartHeader;
	public cartItems: CartItem[] = [];
	public cartStream: Subject<any> = new BehaviorSubject([]);
	public qtyTotal: Subject<number> = new BehaviorSubject(0);
	public priceTotal: BehaviorSubject<number> = new BehaviorSubject(0);
	public shippingCost: BehaviorSubject<number> = new BehaviorSubject(0);
	public tax: BehaviorSubject<number> = new BehaviorSubject(0);

	_COD_IVA_DEFAULT = environment.COD_IVA_DEFAULT;
	PERC_IVA = 22;

	constructor(
		private store: Store<any>,
		private toastrService: ToastrService,
		private translate: TranslateService,
		private http: HttpClient,
		private prezziSpedizioneService: PrezziSpedizioneService,
		public apiService: ApiService) {

		store.pipe(select(cartHeaderSelector)).subscribe(items => {
			this.cartHeader = items;
		})

		store.pipe(select(cartItemsSelector)).subscribe(items => {
			this.cartItems = items;

			this.cartStream.next(items);

			this.qtyTotal.next(
				this.cartItems.reduce((acc, cur) => {
					return acc + cur.qty
				}, 0));

			this.priceTotal.next(
				this.cartItems.reduce((acc, cur) => {
					return acc + cur.sum
				}, 0)
			)

			// this.prezziSpedizioneService.prezzoSpedizione$.subscribe((prezzoSpedizione) => {
			// 	this.cartHeader.shipping = prezzoSpedizione;
			// 	this.calculateTax();
			//   });
		})
	}

	updateCartHeader(cartHeader: CartHeader) {
		const currentTimestamp = this.generateCustomId();
		const idUtente = cartHeader.idUtente?.toString();
		const paddedIdUtente = idUtente.padStart(6, '0');
		cartHeader.custom_id = paddedIdUtente + currentTimestamp;
		this.calculateTax();

		this.store.dispatch(new UpdateCartHeaderAction({ cartHeader }));
	}

	private calculateTax() {
		const priceTotalValue = this.priceTotal.getValue();
		let shipping = this.cartHeader.shipping || 0;
	  
		const taxValue = (priceTotalValue + shipping) * this.PERC_IVA / 100;
	  
		// Verifica se taxValue è un numero valido
		if (!isNaN(taxValue) && isFinite(taxValue)) {
		  this.tax.next(taxValue);
		} else {
		  // Se taxValue non è valido, assegna un valore predefinito o gestisci la situazione di conseguenza
		  this.tax.next(0); // o un valore predefinito appropriato
		}
	  }

	// Aggiorna il prezzo di spedizione quando necessario
	updateShippingCost(newShippingCost: number) {
		this.prezziSpedizioneService.setPrezzoSpedizione(newShippingCost);
		this.cartHeader.shipping = newShippingCost;
		this.calculateTax();
	}

	// Aggiunta Prodotto al Carrello
	addToCart(product: Product, qty = 1) {
		if (this.canAddToCart(product, qty)) {
			this.store.dispatch(new AddToCartAction({ product, qty }));
			this.toastrService.success(this.translate.instant('cart.added'));
			// Prodotto aggiunto al Carrello.
		} else {
			this.toastrService.error(this.translate.instant('cart.addErr'));
			// Spiacente, non puoi aggiungere quella quantità al carrello.
		}
	}


	// Product Removed from the Cart
	removeFromCart(product: CartItem) {
		this.store.dispatch(new RemoveFromCartAction({ product }));
		this.toastrService.success(this.translate.instant('cart.removed'));
		//Product removed from Cart.
	}

	// Product Removed from the Cart
	clearCart(cartItem: CartItem[]) {
		try {
			let product: CartItem;
			for (let i = 0; i < cartItem.length; i++) {
				product = cartItem[i];
				this.store.dispatch(new RemoveFromCartAction({ product }));
			}
		} catch (error) {
			console.error("Errore durante la pulizia del carrello:", error);
			// Puoi gestire l'errore in modo appropriato qui, ad esempio, mostrando un messaggio all'utente.
		}
	}


	// Cart update
	updateCart(cartItems: CartItem[]) {
		this.store.dispatch(new UpdateCartAction({ cartItems }));
		this.toastrService.success(this.translate.instant('cart.updated'));
		//Cart Updated.
	}

	// Check whether product is in Cart or not
	isInCart(product: Product): boolean {
		return this.cartItems.find(item => item.id == product.id) ? true : false;
	}

	canAddToCart(product: Product, qty = 1) {
		const find = this.cartItems.find(item => item.id == product.id);
		qty = Math.max(qty, product.pcs_pack || 1);

		if (qty === 0) {
			qty = 1;
		}
		if (find) {
			if (product.stock == 0 || (product.stock && product.stock < (find.qty + qty))) {
				return false;
			} else if (product.pcs_pack && qty % product.pcs_pack !== 0) {
				// Il numero di pezzi da aggiungere deve essere un multiplo di pcs_pack
				return false;
			} else {
				return true;
			}
		} else {
			if (product.stock == 0 || (product.stock && product.stock < qty)) {
				return false;
			} else if (product.pcs_pack && qty % product.pcs_pack !== 0) {
				// Il numero di pezzi da aggiungere deve essere un multiplo di pcs_pack
				return false;
			} else {
				return true;
			}
		}
	}

	// Conferma il carrello
	async sendCart(): Promise<WSResponseModel> {

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'IdUtente': this.cartHeader.idUtente.toString()
			}),
			rejectUnauthorized: false,
		};

		console.log(this.cartHeader)

		const body = {
			anaData: this.cartHeader.anaCF,
			cartItems: this.cartItems,
			codPag: this.cartHeader.payment,
			speseSped: this.cartHeader.shipping,
			note: this.cartHeader.note
		};

		console.log(body)

		const response = await firstValueFrom(
			this.http.post<WSResponseModel>(
				`${environment.backend.baseURL}/OrdWeb/SalvaCarrello`,
				body,
				httpOptions
			)
		);

		return response;
	}


	createPaypalPayment() {
		const paypalApiUrl = environment.paypal.paypalApiUrl;
		const client_id = environment.paypal.paypalClient_id;
		const client_secret = environment.paypal.paypalClient_secret;
		const domain = window.location.protocol + '//' + window.location.host;

		const _items = [];
		let netPriceTot: number = 0;
		let itemTax: number = 0;
		//let taxTot: number = 0;
		let TotalPrice: number = 0;

		this.cartItems.forEach((cartItem) => {

			itemTax = ((cartItem.price + this.cartHeader.shipping) * this.PERC_IVA) / 100;
			//taxTot += (tax * cartItem.qty);

			_items.push({
				name: cartItem.name,
				description: cartItem.short_desc,
				quantity: cartItem.qty.toString(),
				price: cartItem.price.toFixed(2),
				tax: itemTax.toFixed(2),
				sku: cartItem.id,
				currency: environment.VALUTA
			});
		});

		this.priceTotal.subscribe((priceTotalValue: number) => {
			netPriceTot = priceTotalValue;
			TotalPrice = (priceTotalValue + this.cartHeader.shipping + this.tax.value);
		});


		const dataora = new Date()

		// Dati del pagamento
		const pagamento = {
			intent: `sale`,
			payer: {
				payment_method: `paypal`
			},
			transactions: [
				{
					amount: {
						total: `${TotalPrice.toFixed(2)}`,
						currency: environment.VALUTA,
						details: {
							subtotal: netPriceTot.toFixed(2),
							tax: this.tax.value.toFixed(2),
							shipping: this.cartHeader.shipping.toFixed(2),
							handling_fee: `0`,
							shipping_discount: `0`,
							insurance: `0`
						}
					},
					description: `Pagamento per Ordine Web MyLiteLed`,
					custom: `${this.cartHeader.custom_id}`, //univoco della tranzazione
					//invoice_id: `${DOC_ID}`,
					payment_options: {
						allowed_payment_method: `INSTANT_FUNDING_SOURCE`
					},
					soft_descriptor: `Ordine Web MyLiteLed ${dataora}`,
					item_list: {
						items: _items,
						shipping_address: {
							recipient_name: `${this.cartHeader.anaCF.privateCli ? this.cartHeader.anaCF.name + ' ' + this.cartHeader.anaCF.lastname : this.cartHeader.anaCF.company}`,
							line1: this.cartHeader.anaCF.address.address,
							//line2: `Unit #34`,
							city: this.cartHeader.anaCF.address.city,
							country_code: this.cartHeader.anaCF.address.country,
							postal_code: this.cartHeader.anaCF.address.pcode,
							phone: this.cartHeader.anaCF.phone,
							//state: this.cartHeader.anaCF.address.state
						}
					}
				}
			],
			note_to_payer: `Contattaci per informazioni sul tuo ordine:  telefono +39 0444 821261 o email info@myliteled.com`,
			redirect_urls: {
				return_url: `${domain}/${environment.paypal.paypalReturn}`,
				cancel_url: `${domain}/${environment.paypal.paypalCancelReturn}`
			}
		};

		//console.log(pagamento)

		// Codifica le credenziali API per l'autenticazione
		const base64Auth = btoa(`${client_id}:${client_secret}`);
		const headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', `Basic ${base64Auth}`);

		// Effettua la richiesta per creare il pagamento
		this.http.post<any>(paypalApiUrl, pagamento, { headers: headers }).subscribe({
			next: (response) => {
				// La risposta contiene l'URL di approvazione di PayPal
				const approvalUrl = response.links.find(link => link.rel === 'approval_url').href;
				this.toastrService.success('PayPal', this.translate.instant('msg.paymentOk'));

				// Reindirizza l'utente a PayPal per completare il pagamento
				window.location.href = approvalUrl;
			},
			error: (error) => {
				this.toastrService.error('PayPal', `${this.translate.instant('msg.paymentKo')} ${error}`);
			}
		});

	}

	async regPayment(docId: string, paymentId: string) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'IdUtente': this.cartHeader.idUtente.toString()
			}),
			rejectUnauthorized: false,
		};

		const body = {
			docId: docId,
			paymentId: paymentId,
		};
		await firstValueFrom(
			this.http.post<WSResponseModel>(
				`${environment.backend.baseURL}/OrdWeb/regPayment`,
				body,
				httpOptions
			)
		);
	}

	generateCustomId(): string {

		const date: Date = new Date();
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mese è zero-based, quindi aggiungiamo 1
		const year = date.getFullYear().toString();
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');

		return `${year}${month}${day}${hours}${minutes}${seconds}`;
	}
}